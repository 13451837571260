import getCdnUrl from '../helpers/get-cdn-url';

import { FinanceIcon, RateGraphIcon, ReviewDocumentIcon, VisualizeAccountIcon } from './icons/tyc';

const InstagramIcon = getCdnUrl('/common_assets/assets/icons/instagram_icon.png');
const KobrosPerson = getCdnUrl('/common_assets/assets/icons/kobros_person.png');
const LinkedinIcon = getCdnUrl('/common_assets/assets/icons/linkedin_icon.png');
const LookIcon = getCdnUrl('/common_assets/assets/icons/look_icon.png');
const PDFIcon = getCdnUrl('/common_assets/assets/icons/pdf.svg');
const SendIcon = getCdnUrl('/common_assets/assets/icons/send_icon.png');
const WhatsappIcon = getCdnUrl('/common_assets/assets/icons/whatsapp.png');
const XMLIcon = getCdnUrl('/common_assets/assets/icons/xml.svg');
// illustrations folder
const FingoPlatformBig = getCdnUrl('/common_assets/assets/illustrations/fingo_platform_big.png');
const FingoPlatformMd = getCdnUrl('/common_assets/assets/illustrations/fingo_platform_md.png');
const SideBanner = getCdnUrl('/common_assets/assets/illustrations/login_side_banner.png');
const PasswordRecoveryBanner = getCdnUrl('/common_assets/assets/illustrations/password_recovery_banner.png');
const TermsAndConditions = getCdnUrl('/common_assets/assets/illustrations/terms_and_conditions.png');
const WelcomePerson = getCdnUrl('/common_assets/assets/illustrations/welcome_person.png');
// images folder
const BackgroundMosaic = getCdnUrl('/common_assets/assets/images/background_mosaic.avif');
const BrokenChain = getCdnUrl('/common_assets/assets/images/broken-chain.png');
const FiFingo = getCdnUrl('/common_assets/assets/images/fi_fingo.jpg');
const FingoWhite = getCdnUrl('/common_assets/assets/images/fingomas_white.png');
const HomeBackground = getCdnUrl('/common_assets/assets/images/home_background.jpg');
const Rose = getCdnUrl('/common_assets/assets/images/home_rose.png');
const KobrosLogo = getCdnUrl('/common_assets/assets/images/kobros_logo.png');
const KobrosLogoWhite = getCdnUrl('/common_assets/assets/images/kobros_logo_white.png');
const LoadingMan = getCdnUrl('/common_assets/assets/images/loading_man.gif');
const AceptaLogo = getCdnUrl('/common_assets/assets/images/logo_acepta.png');
const FingoChristmas = getCdnUrl('/common_assets/assets/images/logo_fingo_christmas.png');
const LokalLogo = getCdnUrl('/common_assets/assets/images/lokal_logo.svg');
const MrKobroSitting = getCdnUrl('/common_assets/assets/images/mr_kobros_sitting.png');
const MrKobroSittingWall = getCdnUrl('/common_assets/assets/images/mr_kobros_sitting_on_a_wall.svg');
const PartnerAceptaLogo = getCdnUrl('/common_assets/assets/images/partner/acepta.png');
const PartnerManagerLogo = getCdnUrl('/common_assets/assets/images/partner/manager.png');
const PartnerSenegociaLogo = getCdnUrl('/common_assets/assets/images/partner/senegocia.png');
const ReasonCompetitive = getCdnUrl('/common_assets/assets/images/reasons/competitive.png');
const ReasonFast = getCdnUrl('/common_assets/assets/images/reasons/fast.png');
const ReasonOnline = getCdnUrl('/common_assets/assets/images/reasons/online.png');
const ReasonTransparent = getCdnUrl('/common_assets/assets/images/reasons/transparent.png');
const SatLogo = getCdnUrl('/common_assets/assets/images/sat_logo.png');
const SiiLogo = getCdnUrl('/common_assets/assets/images/sii_logo.png');
const StepOneImage = getCdnUrl('/common_assets/assets/images/steps/step1.png');
const StepTwoImage = getCdnUrl('/common_assets/assets/images/steps/step2.png');
const StepThreeImage = getCdnUrl('/common_assets/assets/images/steps/step3.png');
const StepFourImage = getCdnUrl('/common_assets/assets/images/steps/step4.png');
const WhatIsFingo = getCdnUrl('/common_assets/assets/images/what_is_fingo.png');
const BlueBackground = getCdnUrl('/common_assets/assets/decoration/blue_background.png');
const BottomLeftWave = getCdnUrl('/common_assets/assets/decoration/bottom_left_wave_terms_and_conditions.png');
const BottomWaveLogin = getCdnUrl('/common_assets/assets/decoration/bottom_wave_login.png');
const BottomWaveRegister = getCdnUrl('/common_assets/assets/decoration/bottom_wave_register.png');
const PeopleHug = getCdnUrl('/common_assets/assets/decoration/people_hug.png');
const PinkBackground = getCdnUrl('/common_assets/assets/decoration/pink_background.png');
const RedWave = getCdnUrl('/common_assets/assets/decoration/red_wave.svg');
const Wave = getCdnUrl('/common_assets/assets/decoration/register_bg.png');

// icons folder
const AskIcon = getCdnUrl('/common_assets/assets/icons/ask_icon.png');
const CollectIcon = getCdnUrl('/common_assets/assets/icons/collect_icon.png');
const EmailSent = getCdnUrl('/common_assets/assets/icons/email_sent.png');
const FingoHalloween = getCdnUrl('/common_assets/halloween_fingo_logo.png');
const FingoLogo = getCdnUrl('/logos/fingo-desktop@2x-80q.webp');
const FingoChile = getCdnUrl('/common_assets/logo_fingo_chilean_national_day.png');

export {
  BlueBackground,
  BottomLeftWave,
  BottomWaveRegister,
  BottomWaveLogin,
  RedWave,
  PeopleHug,
  PinkBackground,
  Wave,
  // icons
  AskIcon,
  CollectIcon,
  LookIcon,
  PDFIcon,
  XMLIcon,
  SendIcon,
  InstagramIcon,
  KobrosPerson,
  LinkedinIcon,
  EmailSent,
  WhatsappIcon,
  ReviewDocumentIcon,
  VisualizeAccountIcon,
  FinanceIcon,
  RateGraphIcon,
  // illustrations
  SideBanner,
  WelcomePerson,
  FingoPlatformBig,
  FingoPlatformMd,
  TermsAndConditions,
  PasswordRecoveryBanner,
  // images
  FingoLogo,
  FingoHalloween,
  FingoChristmas,
  FingoChile,
  FiFingo,
  FingoWhite,
  BrokenChain,
  AceptaLogo,
  LokalLogo,
  SiiLogo,
  SatLogo,
  Rose,
  HomeBackground,
  BackgroundMosaic,
  PartnerAceptaLogo,
  PartnerManagerLogo,
  PartnerSenegociaLogo,
  ReasonCompetitive,
  ReasonFast,
  ReasonOnline,
  ReasonTransparent,
  StepOneImage,
  StepTwoImage,
  StepThreeImage,
  StepFourImage,
  LoadingMan,
  WhatIsFingo,
  KobrosLogo,
  MrKobroSitting,
  MrKobroSittingWall,
  KobrosLogoWhite,
};
