/* eslint-disable consistent-return */
import { offsetLimitPagination } from '@apollo/client/utilities';
import getObjectFromCache from './getObjectsFromCache';

const PAGINATED_ARGS = ['after', 'before', 'offset', 'first', 'last'];

const managerPaginationCache = (keyArgs, returnTypename) => {
  const offsetPag = offsetLimitPagination();
  const read = (existing, options) => {
    if (!existing) {
      if (!returnTypename) return existing;
      if (options.args.id_In) {
        const cachedObjects = getObjectFromCache(options.cache, returnTypename, options.args.id_In);
        if (cachedObjects.length !== options.args.id_In.length) return;
        return {
          pageInfo: {
            startCursor: null,
            endCursor: null,
            hasPreviousPage: false,
            hasNextPage: false,
          },
          totalPages: 1,
          totalCount: cachedObjects.length,
          edges: cachedObjects.map((obj) => ({
            node: obj,
            cursor: null,
          })),
        };
      }
      return existing;
    }
    const offset = options.args.offset || 0;
    const first = options.args.first || 10;
    const limit = Math.min(offset + first, existing.totalCount);
    if (limit > existing.edges.length) return;
    const newReturn = existing.edges.slice(offset, limit);
    if (newReturn.length !== Object.values(newReturn).length) {
      return;
    }
    return {
      ...existing,
      edges: newReturn,
      pageInfo: {
        ...existing.pageInfo,
        hasPreviousPage: offset > 0,
        hasNextPage: limit < existing.totalCount,
      },
      totalPages: Math.ceil(existing.totalCount / first),
    };
  };
  const merge = (existing, incoming, options) => {
    const newMerged = offsetPag.merge(existing?.edges, incoming.edges, options);
    return {
      ...incoming,
      edges: newMerged,
    };
  };
  const keyArgsFunction = (fields) => Object.keys(fields).filter(
    (field) => !PAGINATED_ARGS.includes(field),
  ).sort();
  return {
    keyArgs: keyArgs || keyArgsFunction,
    read,
    merge,
  };
};

export default managerPaginationCache;
