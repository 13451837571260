const ROOT = 'apolloVariables';

const getRoot = () => JSON.parse(localStorage.getItem(ROOT));

const setRoot = (root) => localStorage.setItem(ROOT, JSON.stringify(root));

export const getLocalStorageVariable = (name) => {
  const root = getRoot();
  return root && root[name];
};

export const setLocalStorageVariable = (name, value) => {
  const root = getRoot();
  const newRoot = { ...root, [name]: value };
  setRoot(newRoot);
};
