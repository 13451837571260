import { makeVar } from '@apollo/client';
import { getLocalStorageVariable } from '../helpers/local-storage';

export const requestingPlatformIdVar = makeVar(
  getLocalStorageVariable('requestingPlatformIdVar'),
);

export const selectedCompanyRut = makeVar(
  getLocalStorageVariable('selectedCompanyRut') || null,
);
